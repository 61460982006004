<template>
	<div class="invite-index-page">
		<van-image
			fit="contain"
			:src="resourceURL + 'images/invite/logo.png'"
			class="logo"
		/>
		
		<van-form @submit="onSubmit" class="content" ref="formItem" :show-error-message="false">
			
			<van-row>
				<van-col span="8">
					<van-field
						v-model="formItem.country_code"
						readonly
						@click="showPicker = true"
						style="border-radius: 24px!important;margin-bottom:15px;"
					/>
				</van-col>

				<van-col span="16">
					<van-field
						v-model="formItem.telephone"
						type="tel"
						placeholder="请输入手机号码"
						:rules="[{ required: true, trigger: 'onSubmit', message: '请填写手机号码' }]"
						style="border-radius: 24px!important;margin-bottom:15px;"
					/>
				</van-col>
			</van-row>

			<van-field
				v-model="formItem.sms_code"
				type="number"
				placeholder="请输入验证码"
				:rules="[{ required: true, trigger: 'onSubmit', message: '请填写验证码' }]"
				style="border-radius: 24px!important;margin-bottom:15px;"
			>
				<template #button>
					<van-button size="mini" type="primary" block native-type="button" :disabled="disabledSendCode" @click="sendSMSCode">{{send_text}}</van-button>
				</template>
			</van-field>
			
			<van-row>
				<van-col span="24">
					<van-button round block color="#FFAC00" native-type="submit">下一步</van-button>
				</van-col>
			</van-row>

			<van-overlay :show="isWeiXin">
				<div class="wrapper">
					<div class="weixin-browser"></div>
				</div>
			</van-overlay>
	
		</van-form>

		<van-popup v-model="showPicker" round position="bottom">
			<van-picker
				show-toolbar
				:columns="columns"
				@cancel="showPicker = false"
				@confirm="onConfirm"
			/>
		</van-popup>

	</div>
</template>

<script>
	export default {
		name: 'invite_index',
		data() {
			return {
				showPicker: false,

				columns : ['086', '852', '853'],

				formItem: {
					invite_id: "",
					code: "",
					country_code: "086",
					telephone: "",
					sms_code: "",
				},

				send_text: "发送验证码",
				disabledSendCode: false,

				second: 60,
				clock: "",

				resourceURL: this.$axios.defaults.resourceURL,

				isWeiXin: false,
				
				allow_invite: true
			}
		},
		mounted() {
			if (this.$route.query.code == "" || this.$route.query.code == undefined) {
				this.$router.replace('/nologin')
			} else {
				this.formItem.code = this.$route.query.code
			}

			//window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
			var ua = window.navigator.userAgent.toLowerCase();
			//alert(JSON.stringify(ua))
			//通过正则表达式匹配ua中是否含有MicroMessenger字符串
			if(ua.match(/MicroMessenger/i) == 'micromessenger'){
				this.isWeiXin = true
			} else{
				this.isWeiXin = false

				this.loadURL()
			}

			this.$axios({
				method: 'post',
				url: 'invite/check', 
				data: {
					code: this.formItem.code
				}
			}).then ((res) => {
				if (res.success) {
					if (!res.data.allow) {
						this.allow_invite = false
						this.$toast.fail('邀请功能维护中，暂无法注册')
					}
				}else{
					this.$toast.fail(res.error_text)
				}
			})
		},
		methods: {

			onConfirm(value) {
				this.formItem.country_code = value
				this.showPicker = false
			},

			loadURL() {
				var u = window.navigator.userAgent;
				var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
				// var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

				if (isAndroid) {
					window.location.href = 'huabo://huaboandroidhost:1024/main'
				}

				// if(isIOS){
				// 	window.location.href = 'huabo://';
				// }
			},

			doLoop() {
				this.second--;
				if(this.second > 0){
					this.send_text = this.second + '秒'
				}else{
					clearInterval(this.clock); //清除js定时器
					this.disabledSendCode = false;
					this.send_text = '发送验证码';
					this.second = 60; //重置时间
				}
			},

			sendSMSCode() {

				if (!this.allow_invite) {
					this.$toast.fail('邀请功能维护中，暂无法注册')

					return false
				}

				if(this.formItem.telephone == ""){
					this.$toast.fail('请输入正确的手机号')
					return false
				}

				this.$axios({
					method: 'post',
					url: 'invite/sendsmscode', 
					data: this.formItem
				}).then ((res) => {
					if (res.success && res.data.invite_id) {
						this.formItem.invite_id = res.data.invite_id

						this.$toast.success('验证码发送成功')

						this.disabledSendCode = true

						this.send_text = this.second + '秒'
						
						this.clock = setInterval(this.doLoop, 1000); //一秒执行一次

						
					}else{
						this.$toast.fail(res.error_text)
					}
				})
			},
			
			onSubmit() {
				if(this.formItem.invite_id == ""){
					this.$toast.fail('数据错误，请重新刷新页面')
					return false
				}

				this.$axios({
					method: 'post',
					url: 'invite/checksmscode',
					data: this.formItem
				}).then ((res) => {
					if (res.success) {
						this.$router.replace('/invite/password?invite_id=' + this.formItem.invite_id)
					}else{
						this.$toast.fail(res.error_text)
					}
				})
			}
		},
	}
</script>

<style scoped>
.send-code-btn {
	display:inline-block;
	text-align:right;
	float:right;
	color:#FDF773;
	transform: translateY(10px);
}
</style>